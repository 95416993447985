<template>
  <div class="col-sm-8 col-md-7 col-xl-11 col-xxl-8">
    <button v-if="referrer" class="ButtonBack"
            onclick="history.back();">
      <img src="@/assets/grayBackArrow.svg" alt="">
      Back
    </button>
    <a v-if="!referrer" class="ButtonBack"
       :href="landingUrl">
      <img src="@/assets/grayBackArrow.svg" alt="">
      Back
    </a>
    <h4 class="mb-1 mb-lg-2 title">CREATE YOUR ACCOUNT</h4>
    <div class="py-4">
      <form class="row g-4 signup-form" @submit.prevent="signUp">
        <div class="col-12">
          <LandingInput
            placeholder="First name"
            class="w-100"
            @changeValue="(data) => changeInputValue(data, 'name')"
            :required="true"
            :min-width="'285'"
            :value-key="'first_name'"
            :v="$v.signupData.first_name"
            :input-class="$v.signupData.first_name.$error && 'error'"
          />
          <span class="error p-2"
                v-if="!$v.signupData.first_name.required && $v.signupData.first_name.$dirty">{{ $t('error.required', { field: 'First name' }) }}</span>
          <span class="error p-2"
                v-if="!$v.signupData.first_name.alpha">{{ $t('error.alpha', { field: 'First name' }) }}</span>
          <span class="error p-2" v-if="!$v.signupData.first_name.minLength">{{
              $t('error.minLength', {
                field: 'First name',
                length: 2
              })
            }}</span>
          <span class="error p-2"
                v-if="exceptionMessageBySourceParameter('name')">{{ exceptionMessageBySourceParameter('name') }}</span>
        </div>
        <div class="col-12">
          <LandingInput
            placeholder="Last name"
            class="w-100"
            :min-width="'285'"
            @changeValue="(data) => changeInputValue(data, 'last_name')"
            :required="true"
            :value-key="'last_name'"
            :v="$v.signupData.last_name"
            :input-class="$v.signupData.last_name.$error && 'error'"
          />
          <span class="error p-2"
                v-if="!$v.signupData.last_name.required && $v.signupData.last_name.$dirty">{{ $t('error.required', { field: 'Last name' }) }}</span>
          <span class="error p-2"
                v-if="!$v.signupData.last_name.alpha">{{ $t('error.alpha', { field: 'Last name' }) }}</span>
          <span class="error p-2" v-if="!$v.signupData.last_name.minLength">{{
              $t('error.minLength', {
                field: 'Last name',
                length: 2
              })
            }}</span>
          <span class="error p-2" v-if="exceptionMessageBySourceParameter('last_name')">{{
              exceptionMessageBySourceParameter('last_name')
            }}</span>
        </div>
        <div class="col-12">
          <LandingInput
            type="email"
            v-model="signupData.email"
            placeholder="Email address"
            class="w-100"
            @changeValue="(data) => changeInputValue(data, 'email')"
            :required="true"
            :value-key="'email'"
            :min-width="'285'"
            :v="$v.signupData.email"
            :input-class="$v.signupData.email.$error && 'error'"
          />
          <span class="error p-2" v-if="!$v.signupData.email.required && $v.signupData.email.$dirty">{{
              $t('error.required', { field: 'Email' })
            }}</span>
          <span class="error p-2" v-if="!$v.signupData.email.email">{{ $t('error.email', { field: 'Email' }) }}</span>
          <span class="error p-2" v-if="exceptionMessageBySourceParameter('email')">{{
              exceptionMessageBySourceParameter('email')
            }}</span>
        </div>
        <div class="col-md-12 mt-4">
          <LandingInput
            type="password"
            placeholder="Password"
            class="w-100"
            :min-width="'285'"
            @changeValue="(data) => changeInputValue(data, 'password')"
            :required="true"
            :value-key="'password'"
            :v="$v.signupData.password"
            :input-class="$v.signupData.password.$error && 'error'"
          />
          <span class="error p-2" v-if="!$v.signupData.password.required && $v.signupData.password.$dirty">{{
              $t('error.required', { field: 'Password' })
            }}</span>
          <span class="error p-2" v-if="!$v.signupData.password.minLength">{{
              $t('error.minLength', {
                field: 'Password',
                length: 8
              })
            }}</span>
          <span class="error p-2" v-if="exceptionMessageBySourceParameter('password')">{{
              exceptionMessageBySourceParameter('password')
            }}</span>
        </div>
        <!--        <div class="col-md-12 mt-4">-->
        <!--          <LandingInput-->
        <!--            type="password"-->
        <!--            placeholder="Confirm password"-->
        <!--            class="w-100"-->
        <!--            @changeValue="(data) => changeInputValue(data, 'password')"-->
        <!--            :min-width="'285'"-->
        <!--            :required="true"-->
        <!--            :value-key="'password_confirmation'"-->
        <!--            :v="$v.signupData.password_confirmation"-->
        <!--          />-->
        <!--          <span class="error p-2"-->
        <!--                v-if="!$v.signupData.password_confirmation.required && $v.signupData.password_confirmation.$dirty && $v.signupData.password_confirmation.sameAs">-->
        <!--            {{ $t('error.required', { field: 'Password confirmation' }) }}-->
        <!--          </span>-->
        <!--          <span class="error p-2"-->
        <!--                v-if="!$v.signupData.password_confirmation.sameAs && $v.signupData.password_confirmation.$dirty">-->
        <!--            {{-->
        <!--              $t('error.sameAs', {-->
        <!--                field: 'Password confirmation',-->
        <!--                name: 'password'-->
        <!--              })-->
        <!--            }}-->
        <!--          </span>-->
        <!--          <span class="error p-2"-->
        <!--                v-if="exceptionMessageBySourceParameter('password')">-->
        <!--            {{ exceptionMessageBySourceParameter('password') }}-->
        <!--          </span>-->
        <!--        </div>-->
        <div class="captcha-block col-12">
          <div class="captcha-block-content">
            <vue-recaptcha ref="recaptcha"
                           v-if="isProduction"
                           @verify="verify()"
                           @expired="onCaptchaExpired()"
                           @render="renderMethod($event)"
                           :sitekey="recaptcha_public_key"
                           :loadRecaptchaScript="true">
            </vue-recaptcha>
          </div>
        </div>
        <!--        <div class="col-12">-->
        <!--          <LandingCheckbox-->
        <!--            :container_class="'form-check'"-->
        <!--            :name="'policyCheck'"-->
        <!--            :id="'policyCheck'"-->
        <!--            :label="`<span>I accept the terms of the User Agreement and give my consent to-->
        <!--                     AutomatedRules to process my personal information on the terms determined-->
        <!--                     by the <a style='color: #2364BE' href=${landingUrl + '/privacy'}>Privacy Policy.</a></span>`"-->
        <!--            @change="ndaClick"-->
        <!--            :value="true"-->
        <!--            :checked="signupData.nda"-->
        <!--            :error="!$v.signupData.nda.accepted && $v.signupData.nda.$dirty"-->
        <!--            :v="$v.signupData.nda"-->
        <!--          >-->
        <!--          </LandingCheckbox>-->
        <!--        </div>-->
        <span class="my-4 terms_service">
          By continuing, you agree to AutomatedRules <a :href="landingUrl + 'terms'">Terms of Service</a>
        </span>
        <div>
          <button :disabled="!signupData.nda" class="signup-btn">SIGN UP</button>
        </div>
        <router-link class='link text-center' :to="{ name: 'SignIn' }">Already have an account? <span>Log in</span></router-link>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import LandingInput from '@/components/landings/LandingInput.vue'
import LandingCheckbox from '@/components/landings/LandingCheckbox.vue'
import VueRecaptcha from 'vue-recaptcha'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { required, alpha, minLength, email, sameAs } from 'vuelidate/lib/validators'
import { accepted } from '../../i18n/validators'
import { messages } from '../../i18n/messages'

export default Vue.extend({
  components: {
    LandingInput,
    // LandingCheckbox,
    VueRecaptcha
  },
  metaInfo: {
    title: 'Get Started with AutomatedRules',
    meta: [
      {
        name: 'description',
        content: 'Start your Free plan with unlimited access to all AutomatedRules features. No credit card required.'
      }
    ]
  },
  validations: {
    signupData: {
      first_name: {
        required,
        alpha,
        minLength: minLength(2)
      },
      last_name: {
        required,
        alpha,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      }
      // password_confirmation: {
      //   required,
      //   sameAs: sameAs('password')
      // },
      // nda: {
      //   accepted
      // }
    }
  },
  i18n: {
    messages: messages
  },
  data: () => ({
    signupData: {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      password_confirmation: null,
      nda: true
    },
    referrer: false,
    captcha_value: '',
    recaptcha_public_key: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
    loadId: ''
  }),
  mounted () {
    const url = new URL(document.location.href)
    this.referrer = url.searchParams.get('back')
    this.$refs.recaptcha && this.$refs.recaptcha.reset()
    if (this.$route.query.email) {
      this.signupData.email = this.$route.query.email
    }
  },
  computed: {
    ...mapGetters('exceptions', ['exceptionMessageBySourceParameter']),
    ...mapGetters('profile', ['currentUser']),
    landingUrl () {
      return process.env.VUE_APP_LANDING_URL
    },
    isProduction () {
      return process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    ...mapMutations('exceptions', ['clearExceptionBySourceParameter']),
    ...mapActions('profile', ['registerUser']),
    ...mapActions('notifications', ['addNotification']),
    renderMethod (event) {
      this.loadId = event > 0 ? 'g-recaptcha-response' + '-' + event : 'g-recaptcha-response'
    },
    verify () {
      this.captcha_value = document.getElementById(this.loadId).value
    },
    onCaptchaExpired () {
      this.$refs.recaptcha && this.$refs.recaptcha.reset()
    },
    changeInputValue (data, type = undefined) {
      this.signupData[data.key] = data.value
      if (type) {
        this.clearExceptionBySourceParameter(type)
      }
    },
    signUp () {
      if (!this.$v.$invalid) {
        this.registerUser({
          type: 1,
          name: this.signupData.first_name,
          last_name: this.signupData.last_name,
          email: this.signupData.email,
          password: this.signupData.password,
          password_confirmation: this.signupData.password,
          nda: this.signupData.nda ? 1 : 0,
          'g-recaptcha-response': this.captcha_value
        })
          .then(() => {
            if (this.$gtm && this.$gtm.enabled()) {
              // eslint-disable-next-line no-unused-expressions
              window.dataLayer?.push({
                event: 'sign_up',
                method: 'email',
                userId: this.currentUser.id
              })
            }
            this.$router.push({ name: 'Activate' })
          })
          .catch(e => {
            if (e.response && e.response.data.errors) {
              this.addNotification({
                body: e.response.data.errors[0].detail[0],
                type: 'danger'
              })
              this.$refs.recaptcha && this.$refs.recaptcha.reset()
            }
          })
      } else {
        this.$v.$touch()
      }
    },
    ndaClick (val) {
      this.signupData.nda = val
    }
  }
})
</script>

<style lang="scss">
.title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.5em;
  line-height: 66px;
  color: #000000;
  @media (max-width: 576px) {
    font-size: 24px;
  }
}

.select {
  ::v-deep .vs__dropdown-toggle {
    height: 50px;

    .vs__selected {
      height: 100%;
      margin: 0 10px;
    }
  }
}

.form-check {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1em;
  padding-left: 0;

  &-input {
    width: 18px;
    height: 18px;
  }
}

.country-select ::v-deep .selected-container {
  margin-left: 13px;
}

::v-deep .country {
  &-wrapper {
    position: relative;
  }

  &-flag {
    position: absolute;
    left: 13px;
    top: calc(50% - 16px);
  }

  &-input {
    padding-left: 55px !important;
    border: 1px solid $input-border;
    border-radius: 3px;
  }
}

button.signup-btn {
  background: #00BAFF;
  border-radius: 30px;
  font-family: 'Roboto Flex', sans-serif;
  height: 100%;
  min-height: 50px;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.60;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.captcha-block {
  .captcha-block-content {
    border-radius: 15px;
    background: #F9F9F9;
    padding: 15px 10px;
  }
}
.terms_service {
  color: #666666;
  font-family: 'Merge One', sans-serif;
  font-size: 16px;
  letter-spacing: 0.03em;
  span {
    color: #00BAFF;
  }
}

@media (min-width: 991px) {
  .country-select {
    ::v-deep .selected {
      height: 45px;
    }
  }
}

.link {
  margin-top: 3rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #000000;
  &:hover {
    color: #000000;
  }
  span {
    color: #00BAFF;
    text-decoration: underline;
  }
}

</style>
